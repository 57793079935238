.dumping-points-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto;
}

.dumping-points-title {
  color: var(--global--placeholder--Color);
  margin-bottom: 5px;
}

.dumping-points-translation {
  font-style: italic;
  color: #949393;
  margin: 0;
  font-size: 12px;
}

.instructions-italic {
  font-style: italic;
}

.dumping-points-indications h1,
.dumping-points-attending h1,
.dumping-points-map h1,
.dumping-points-form h1,
.dumping-points-instructions h1,
.instructions-english-title {
  color: var(--global--placeholder--Color);
  margin-bottom: 5px;
  font-size: 24px;
}

.indications-container {
  width: 100%;
  height: 680px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  flex-flow: wrap;
  padding-top: 20px;
}

.usage-step {
  width: 220px;
  height: 140px;
  border: 1px solid var(--global--placeholder--Color);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.usage-step .step-number {
  font-size: 50px;
  width: 50px;
  background-color: rgb(0,164,155);
  color: white;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.usage-step .step-text {
  width: 160px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.attending-hours p {
  margin-bottom: 5px;
}

.dumping-points-form {
  padding: 30px 0px;
}

.dumping-points-form button {
  background-color: rgb(0,164,155);
  margin-top: 15px;
  color: white;
  width: 300px;
  height: 60px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.dumping-instruction-box {
  border-radius: 5px;
  border: 1px solid rgb(0,164,155);
  padding: 15px;
  margin: 20px 0px;
}

.dumping-instruction-box ul {
  padding-left: 30px;
}

.dumping-instruction-box.dumping-points-translation {
  color: unset;
}

@media only screen and (min-width: 600px) {
  .indications-container {
    height: 350px;
  }

  .dumping-points-container {
    max-width: 500px;
  }
}