.icon-container-dev {
  height: 300px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
}
  
.icon-container-dev img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper-areas {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
}

.text-wrapper-areas h1 {
  font-size: 28px;
}

.page-container-areas {
  width: 80vw;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .page-container-areas {
    padding-left: 120px;
  }

  .text-wrapper-areas h1 {
    font-size: 56px;
  }
}