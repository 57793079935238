.icon-container-compliance{
    height: 600px;
    width: 150px;
    position: absolute;
    right: 0px;
    top: 271px;
    object-fit: contain;
    /* border: 1px solid black; */
    overflow: hidden;
    display: none;
}
    
.icon-container-compliance img {
    width: auto;
    height: 800px;
    position: absolute;
    left: -311px;
    top: -108px;
}

.compliance-wrapper h1 {
    font-size: 28px;
    color: var(--global--placeholder--Color);
}

.compliance-wrapper {
  margin: 0 auto;
  max-width: 300px;
}

.denuncia-files a {
  margin: 15px 0px;
}

.denuncia-files a:hover {
  cursor: pointer;
}

.denuncia-files a:link {
  color: var(--global--text--Color);
  text-decoration: underline;
}

.denuncia-files a:visited {
  color: var(--global--text--Color);
  text-decoration: underline;
}

@media only screen and (min-width: 1024px) {
  .icon-container-compliance{
    display: unset;
  }

  .compliance-wrapper h1 {
    font-size: 56px;
    color: var(--global--placeholder--Color);
  }

  .compliance-wrapper {
    max-width: 700px;
  }
}