.icon-container-ticket {
  display: none;
  height: 600px;
  width: 150px;
  position: absolute;
  right: 0px;
  top: 271px;
  object-fit: contain;
  /* border: 1px solid black;  */
  overflow: hidden;
  }
  
.icon-container-ticket img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -304px;
  top: -109px;
}

.ticket-container {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 80vw;
}

.ticket-container h1 {
  font-size: 28px;
}

.consult-button{
  width: 150px;
  height: 30px;
  background-color: var(--global--placeholder--Color);
  font-weight: bold;
}

.image-container-ticket img{
  width: 80vw;
  margin-right: 20px;
}

.text-container-ticket {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
}

.ticket-information {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.input-container {
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 10px;
}

.input-container label {
  font-size: 12px;
}

.information-input-ticket {
  margin-top: 10px;
  height: 25px;
  width: 160px;
  border-radius: 3px;
  border: 1px solid var(--global--placeholder--Color);

}

.ticket-button {
  width: 140px;
  height: 30px;
  background-color: var(--global--placeholder--Color);
  font-weight: bold;
}

.ticket-button:disabled {
  background-color: #ccc;
}

.error-message {
  color: red;
}

.loading {
  padding-top: 25px;
  width: 140px;
  text-align: center;
}

.loading img {
  max-width: 44px;
  max-height: 44px;
}

@media screen and (min-width: 1024px) {
  .ticket-information {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .icon-container-ticket {
    display: unset;
    height: 600px;
    width: 150px;
    position: absolute;
    right: 0px;
    top: 271px;
    object-fit: contain;
    /* border: 1px solid black;  */
    overflow: hidden;
  }
    
  .icon-container-ticket img {
    width: auto;
    height: 800px;
    position: absolute;
    left: -304px;
    top: -109px;
  }

  .image-container-ticket img{
    height: 550px;
    width: auto;
    margin-right: 20px;
  }

  .text-container-ticket {
    display: flex;
    flex-direction: column;
    padding-left: 75px;
  }

  .ticket-container h1 {
    font-size: 56px;
  }

  .ticket-container {
    color: var(--global--placeholder--Color);
    display: flex;
    flex-direction: column;
    /* text-align: justify; */
    margin: 0 auto;
    width: 800px;
  }
}