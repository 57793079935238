.pay-option-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
}

.pay-option {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}

.pay-option:hover {
  background-color: unset;
}

.pay-option input {
  cursor: pointer;
}

.pay-option img {
  max-width: 100px;
  height: auto;
  width: auto;
  padding: 40px;
}

.icon-container-payment {
  height: 400px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
  display: none;
}

.icon-container-payment img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
  /* text-align: justify; */
}

.text-wrapper h1 {
  font-size: 35px;
}

.payment-button {
  width: 90px;
  height: 30px;
  background-color: var(--global--placeholder--Color);
  font-weight: bold;
}

.payment-page-container {
  padding-left: 20px;
}

@media only screen and (min-width: 1024px) {
  .icon-container-payment {
    display: unset;
  }

  .payment-page-container {
    padding-left: 120px;
  }
  .pay-option img {
    max-width: 180px;
  }
  .text-wrapper h1 {
    font-size: 56px;
  }
}
