/* Aysen */

.aysen {
  padding-top: 100px;
  padding-bottom: 100px;
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 50px;
  padding-right: 50px;
}

.aysen-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.aysen-info-text {
  padding-right: 100px;
}

.aysen-info-text h2 {
  font-size: 42px;
}

.aysen-info-text p {
  font-size: 24px;
  max-width: 750px;
  line-height: 1.7;
}

.aysen-info-img {
  padding-left: 50px;
}

.aysen-data {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.aysen-data-item {
  margin-left: 100px;
  margin-right: 100px;
}

.aysen-data-item h3 {
  text-align: center;
  font-size: 36px;
  color: var(--global--text--Color);
}

.aysen-data-box {
  position: relative;
  border: 5px solid var(--global--text--Color);
  padding: 20px;
  width: 100px;
  height: 65px;
  margin: 0 auto;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  color: var(--global--text--Color);
}

.whitespace {
  height: 100%;
  position: absolute;
  width: 60px;
  top: 0;
  left: 40px;
  background-color: transparent;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  margin-top: -5px;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
}

/* Agua Potable */

.agua-potable {
  position: relative;
  height: 700px;
}

.agua-potable img {
  max-height: 700px;
  max-width: 80vw;
}

.agua-potable-img {
  text-align: right;
}

.agua-box {
  position: absolute;
  top: 100px;
  left: 160px;
  width: 50vw;
  height: auto;
  max-width: 740px;
  max-height: 500px;
  background-color: rgba(66, 81, 138, 0.7);
  border-radius: 25px;
}

.agua-box-content {
  padding: 50px;
}

.agua-box-content h2 {
  margin-top: 0px;
  font-size: 42px;
  color: #fff;
}

.agua-box-content p {
  line-height: 1.7;
  color: #fff;
  font-size: 24px;
}

@media screen and (min-width: 1600px) {
  .agua-box {
    position: absolute;
    top: 100px;
    right: 750px;
  }
}

/* Compromiso */

.compromiso {
  padding-top: 100px;
  padding-bottom: 120px;
  margin-left: 150px;
  margin-right: 150px;
}

.compromiso h2 {
  padding-left: 20px;
  font-size: 42px;
}

.compromiso-texts {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.compromiso-text {
  padding-left: 20px;
  padding-right: 20px;
}

.compromiso-texts p {
  font-size: 24px;
  line-height: 1.7;
  text-align: left;
}

/* home updated */
.intro-sustainability {
  width: 250px;
  height: 500px;
  margin: 0 auto;
  background-image: url('/img/sostenibilidad-intro-home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 20px;
}

.intro-sustainability-text {
  width: 100%;
  height: 100%;
  background-color: var(--global--blue-box);
  color: var(--global--primary--Color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro-text-box {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  line-height: 25px;
}

.intro-text-box h1 {
  line-height: 25px;
  font-size: 22px;
}

.first-part-home {
  padding-top: 100px;
}

.intro-text-box .blue-button {
  background-color: var(--global-dark-blue);
  width: 150px;
  height: 40px;
  margin-top: 20px;
  font-size: 16px;
}

@media screen and (min-width: 1024px) {
  .intro-text-box .blue-button {
    background-color: var(--global-dark-blue);
    width: 150px;
    height: 40px;
    margin-top: 20px;
    font-size: 20px;
  }
}

.social-media-icons-home {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.social-media-icons-home img {
  width: 35px;
  height: auto;
  margin-right: 10px;
}

.second-part-home {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.message-or-complaint-home-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  height: 250px;
  padding: 25px;
  box-shadow: 0px 0px 5px 0px rgb(153, 152, 152);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.message-or-complaint-text .blue-button {
  background-color: var(--global-dark-blue);
  width: 120px;
  height: 50px;
  font-size: 20px;
}

.message-or-complaint-text {
  width: 250px;
  padding-left: 50px;
}

.social-media-home-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  height: 250px;
  padding: 25px;
  box-shadow: 0px 0px 5px 0px rgb(153, 152, 152);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}

.social-media-home-text {
  width: 235px;
  padding-right: 50px;
  padding-left: 15px;
}

.social-media-home-box .circle-imgs-home {
  position: absolute;
  width: 120px;
  right: -75px;
}

.message-or-complaint-home-box .circle-imgs-home {
  position: absolute;
  width: 120px;
  left: -75px;
}

@media only screen and (min-width: 1024px) {
  .second-part-home {
    padding-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1000px;
    margin: 0 auto;
  }

  .message-or-complaint-home-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
    height: 250px;
    padding: 25px;
    box-shadow: 0px 0px 5px 0px rgb(153, 152, 152);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 0px;
  }

  .message-or-complaint-text {
    width: 300px;
    padding-left: 50px;
  }

  .social-media-home-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
    height: 250px;
    padding: 25px;
    box-shadow: 0px 0px 5px 0px rgb(153, 152, 152);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-top: 0px;
  }
  
  .social-media-home-text {
    width: 300px;
    padding-right: 50px;
  }

  .social-media-home-box .circle-imgs-home {
    position: absolute;
    width: 150px;
    right: -75px;
  }

  .message-or-complaint-home-box .circle-imgs-home {
    position: absolute;
    width: 150px;
    left: -75px;
  }
}



.blue {
  top: -10px;
}

.light-blue {
  top: 85px;
}

.cyan {
  top: 160px;
}

.second-part-home p {
  line-height: 25px;
}

.second-part-home h2 {
  line-height: 30px;
}

.mobile-home-buttons {
  padding-top: 50px;
  margin: 0 auto;
  max-width: 240px;
}

.mobile-home-button {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgb(153, 152, 152);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 10px;
  max-height: 200px;
}

.mobile-home-button img {
  max-height: 80px;
}

.mobile-home-button-text {
  text-transform: uppercase;
  padding-bottom: 10px;
}

.mobile-home-button a:link,
.mobile-home-button a:visited {
  color: var(--global--text--Color);
  text-decoration: none;
}

.home-middle-image {
  width: 100%;
  max-width: 1000px;
  height: auto;
}

@media only screen and (max-width: 1024px) {
  .home-middle-image {
    max-width: 300px;
  }
}


@media only screen and (min-width: 1024px) {
  .mobile-home-buttons {
    display: none;
  }

  .intro-sustainability {
    width: 1000px;
    height: 400px;
  }
  
  .intro-sustainability-text {
    width: 100%;
    height: 100%;
  }
  
  .intro-text-box {
    width: 800px;
    line-height: 25px;
  }

  .intro-text-box h1 {
    line-height: normal;
    font-size: 42px;
  }

}
