.icon-container-dev {
  height: 300px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
}
  
.icon-container-dev img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper-forms {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
  max-width: 800px;
}

.text-wrapper-forms h1 {
  font-size: 28px;
}

.text-wrapper-forms h2 {
  font-size: 20px;
}

.page-container-forms {
  width: 80vw;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .text-wrapper-forms h1 {
    font-size: 56px;
  }

  .text-wrapper-forms h2 {
    font-size: 36px;
  }

  .page-container-forms {
    padding-left: 120px;
  }
}

/* Embed */

.page-container-wrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0 auto;
}

.image-container {
  height: 700px;
  width: 500px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.image-container embed {
  height: 700px;
  width: 500px;
}

.information-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}