.carousel-container {
  
}

.carousel-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 600px;
}

.carousel-arrow-left {
  left: 10px;
  z-index: 10;
}

.carousel-arrow-right {
  right: 10px;
  z-index: 10;
  background-size: cover;
}

.mobile-title {
  position: absolute;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  height: 150px;
  width: 210px;
  background-color: rgba(51,65,113, 0.7);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.mobile-title h1 {
  font-size: 14px;
  text-align: center;
  margin: 0;
}

.mobile-title .subtitle-container {
  text-align: center;
}

.title-container {
  display: none;
  position: absolute;
  left: 150px;
  top: 40px;
  z-index: 15;
  height: 320px;
  width: 520px;
  background-color: var(--global--secondary--BackgroundColor);
  border-radius: 20px;
  opacity: 0.7;
}

.title-text {
  display: none;
  padding: 0px 35px;
  position: absolute;
  left: 150px;
  top: 40px;
  z-index: 20;
  height: 280px;
  width: 467px;
}

.title-text h1 {
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--global--primary--BackgroundColor);
  margin-top: 20px;
  margin-bottom: 15px;
}

.subtitle-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.subtitle-container p {
  color: var(--global--primary--BackgroundColor);
  margin-top: 10px;
  max-width: 200px;
  font-size: 12px;
}

.subtitle-container button {
  border: none;
  background-color: var(--global--placeholder--Color);
  color: var(--global--primary--BackgroundColor);
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
}

.carousel-item {
  position: relative;
}

.news-container {
  display: none;
  position: absolute;
  right: 100px;
  z-index: 15;
  bottom: 40px;
  width: 850px;
  color: var(--global--primary--BackgroundColor);
}

.news-container h2 {
  margin: 0;
  text-align: right;
}

.news-container .boxes-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 15px;
}

.boxes-container > div {
  width: 160px;
  height: 100px;
  border-radius: 5px;
}

.boxes-container > div.selected {
  width: 185px;
  height: 105px;
}

.boxes-container > div img {
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
}

.box-wrapper {
  position: relative;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
}

.box-wrapper:hover {
  cursor: pointer;
  opacity: 0.7;
}

.box-wrapper img {
  max-height: 40px;
  max-width: 40px;
  padding-top: 10px;
  padding-bottom: 5px;
  margin: 0 auto;
}

.news-text {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  color: var(--global--text--Color);
  font-size: 16px;
  text-transform: uppercase;
}

.news-text-bg {
  /* background-color: var(--global--placeholder--Color); */
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  /* opacity: 0.7; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pseudo-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: var(--global--primary--BackgroundColor);
  margin-left: 20px;
  margin-right: 20px;
}

.pseudo-footer p {
  font-size: 18px;
  font-weight: 800;
  color: var(--global--text--Color);
}

.title-wrapper {
  background-color: var(--global--primary--BackgroundColor);
}

.slick-slide > div {
  background-color: var(--global--primary--BackgroundColor);
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.social-media img {
  width: 35px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.phone-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.phone-wrapper img {
  width: 25px;
  height: auto;
  padding-right: 10px;
}

.phone-wrapper a:link,
.phone-wrapper a:visited {
  text-decoration: none;
  color: inherit;
}

@media only screen and (min-width: 1024px) {
  .news-container {
    display: unset;
  }

  .title-text,
  .title-container {
    display: unset;
  }

  .mobile-title {
    display: none;
  }

  .carousel-arrow-left {
    left: 50px;
    z-index: 10;
  }
  
  .carousel-arrow-right {
    right: 50px;
    z-index: 10;
    background-size: cover;
  }

  .title-text .subtitle-container p {
    font-size: 24px;
    max-width: 467px;
  }
  
  .subtitle-container button {
    font-size: 20px;
  }

  .subtitle-container {
    display: unset;
  }
}