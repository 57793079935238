.offices-wrapper {
  max-width: 300px;
  margin: 0 auto;
}

.offices-title-container h1 {
  font-size: 28px;
  color: var(--global--placeholder--Color);
}

.icon-container-offices {
  height: 600px;
  width: 150px;
  position: absolute;
  right: 0px;
  top: 265px;
  object-fit: contain;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 0;
  display: none;
}

.icon-container-offices img {
  width: auto;
  height: 800px;
  position: absolute;
  right: -357px;
  top: -108px;
}

.office-img img {
  max-width: 300px
}

@media only screen and (min-width: 1024px) {
  .offices-title-container h1 {
    font-size: 56px;
  }

  .icon-container-offices {
    display: unset;
  }

  .office-img img {
    max-width: 600px
  }

  .offices-wrapper {
    max-width: 1200px;
    height: unset;
    margin: 0 auto;
  }
}
