.icon-container-rate {
  height: 600px;
  width: 150px;
  position: absolute;
  right: 0px;
  top: 271px;
  object-fit: contain;
  /* border: 1px solid black; */
  overflow: hidden;
  display: none;
  }
  
.icon-container-rate img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -265px;
  top: -108px;
}

.rate-wrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
}

.rate-wrapper h1 {
  font-size: 28px;
  color: var(--global--placeholder--Color);
}

.image-container-rate {
  height: 700px;
  width: 300px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.image-container-rate embed {
  height: 700px;
  width: 300px;
}

.information-wrapper-rate{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rate-wrapper p{
  text-decoration: underline;
  font-size: 12px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
  .icon-container-rate {
    display: unset;
  }

  .rate-wrapper {
    width: 500px;
  }

  .rate-wrapper h1 {
    font-size: 56px;
  }

  .image-container-rate {
    width: 500px;
  }

  .image-container-rate embed {
    width: 500px;
  }
}