
.piping-header {
  display: flex;
  align-items: stretch;
  margin-top: 50px;
  margin-bottom: 50px;
}

.piping-text-container-left {
  flex-grow: 1;
  width: 50%;
  padding: 20px;
  border-radius: 0px 20px 20px 0px;
  font-size: 18px;
}

.piping-text-container-right {
  flex-grow: 1;
  width: 50%;
  border-radius: 20px 0px 0px 20px;
  font-size: 18px;
}

.piping-text-container-center {
  flex-grow: 1;
  width: 50%;
  padding: 50px;
  background-color: #009792;
  color: white;
  margin-left: 80px;
  margin-right: 80px;
  border-radius: 20px 20px 20px 20px;
  font-size: 25px;
  text-align: center;
}


.piping-image-container {
  flex-basis: 40%;
  flex-grow: 0;
  overflow: hidden;

}

.piping-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1024px) {
  
  .piping-header p {
    line-height: 30px;
  }
}
