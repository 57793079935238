.contact-form {
    height: auto;
    width: 250px;
    padding: 5px;
    list-style-type: none;
    color: var(--global--placeholder--Color);
}

.form-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.form-information {
    display: inline-block;
    padding-bottom: 10px;
}

.information-textarea {
    width: 250px;
    height: 125px;
    margin-top: 10px;
    resize: none;
}

.information-input {
    height: 30px;
    width: 250px;
    border: 1px solid var(--global--secondary--BackgroundColor);
    border-radius: 3px;
    margin-top: 10px;
}

.information-submit {
    float: left;
    background-color: var(--global--placeholder--Color);
    border: none;
    border-radius: 5px;
    color: var(--global--primary--Color);
    width: 90px;
    height: 30px;
}

.success-message {
  color: #009900;
}

.error-message {
  color: red;
}

@media only screen and (min-width: 1024px) {
  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .information-textarea {
    width: 600px;
  }

  .contact-form {
    width: 600px;
  }
}
