.work-with-us-form {
    width: 80vw;
    margin: 0 auto;
    list-style-type: none;
    color: var(--global--placeholder--Color);
}

.work-with-us-form-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80vw;
}

.work-with-us-form-information {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
}

.work-with-us-information-input {
    height: 30px;
    width: 250px;
    border: 1px solid var(--global--secondary--BackgroundColor);
    border-radius: 3px;
    margin-top: 10px;
}

.work-with-us-form-information .upload-cv-button {
  background-color: var(--global--secondary--BackgroundColor);
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100px;
}

.work-with-us-wrapper .file-info p {
  margin-bottom: 5px;
}

.work-with-us-form .success-message {
  color: #009900;
}

.work-with-us-form .error-message {
  color: red;
}

.information-submit {
  float: left;
  background-color: var(--global--placeholder--Color);
  border: none;
  border-radius: 5px;
  color: var(--global--primary--Color);
  width: 90px;
  height: 30px;
}

@media screen and (min-width: 1024px) {
    .work-with-us-form {
        height: 600px;
        width: 600px;
        list-style-type: none;
        color: var(--global--placeholder--Color);
    }
    
    .work-with-us-form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 30px;
        width: 600px;
    }

    .work-with-us-form-information {
        display: inline-block;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}