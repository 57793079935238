.company-container-wrapper {
  padding-bottom: 100px;
  margin: 0 auto;
  max-width: 300px;
}

.company-photo-and-description {
  position: relative;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0px;
  width: 300px;
}

.company-photo-and-description img {
  width: 230px;
  height: auto;
}

.company-description {
  position: absolute;
  bottom: unset;
  top: 100px;
  background-color: var(--global--blue-box);
  border-radius: 10px;
  color: var(--global--primary--Color);
  padding: 50px;
}

.mision-vision-container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  width: 300px;
  padding: 10px;
  height: 950px;
  margin-top: 750px;
}

.mision,
.vision {
  height: 350px;
  width: 230px;
  padding: 25px;
  box-shadow: 0px 0px 5px 0px rgb(153, 152, 152);
}

.mision p,
.vision p {
  line-height: 25px;
}

.text-wrapper-company {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
  /* text-align: justify; */
}

.text-wrapper-company h1 {
  font-size: 28px;
}

.paragraph-wrapper {
  margin-bottom: 20px;
}

.directory-wrapper {
  background-color: var(--global--secondary--BackgroundColor);
  padding: 5px;
  border-radius: 15px;
  color: var(--global--primary--Color);
  width: 290px;
  padding-bottom: 20px;
}

.directory-wrapper h2 {
  margin-left: 5px;
  font-size: 1em;
}

.directory-table {
  text-align: left;
  width: 100%;
  padding-left: 5px;
  font-size: 7px;
}

.directory-table th {
  padding-bottom: 5px;
}

.chart-container {
  padding-bottom: 100px;
  padding-top: 100px;
}

.chart-container img {
  width: 300px;
  height: auto;
  display: block;
}

.data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;
  height: 100px;
  width: 300px;
}

.data-container-title {
  text-align: center;
}

.selector {
  width: 150px;
  height: 40px;
  color: var(--global--placeholder--Color);
  font-weight: bold;
  padding: 0px 10px;
}

.selector-container {
  margin-bottom: 50px;
  height: 100%;
}
.selector-content {
  display: flex;
  flex-direction: row;
}

.go-button {
  margin-left: 25px;
  height: 40px;
  padding: 0px 8px;
  border: none;
  border-radius: 5px;
  background-color: var(--global--placeholder--Color);
  color: var(--global--primary--Color);
  font-weight: bold;
}
.selector-and-error {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1024px) {
  .text-wrapper-company h1 {
    font-size: 56px;
  }

  .company-container-wrapper {
    padding-bottom: 100px;
    max-width: 900px;
  }

  .company-photo-and-description {
    height: 800px;
    margin-top: 50px;
    width: 900px;
  }

  .company-photo-and-description img {
    width: 700px;
  }

  .company-description {
    bottom: 50px;
    top: unset;
    padding: 50px;
  }

  .mision-vision-container {
    margin-top: unset;
    width: 800px;
    padding: 50px;
    height: 400px;
  }

  .mision,
  .vision {
    height: 300px;
    width: 300px;
    padding: 25px;
  }

  .directory-wrapper {
    padding: 20px;
    width: 860px;
  }

  .directory-wrapper h2 {
    margin-left: 20px;
    font-size: 1.5em;
  }

  .directory-table {
    padding-left: 20px;
    font-size: 14px;
  }

  .chart-container img {
    width: 900px;
  }

  .data-container {
    height: 300px;
    width: 900px;
  }

  .selector {
    width: 200px;
    height: 40px;
    font-weight: bold;
    padding: 0px 20px;
  }
}
