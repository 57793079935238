.icon-container-dev-pm {
  height: 300px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
  display: none;
}
  
.icon-container-dev-pm img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper-pm {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
}

.text-wrapper-pm h1 {
  font-size: 28px;
}

.page-container-dev-pm {
  padding-left: 10px;
}

.payment-methods ul {
  list-style-type: none;
}

.payment-item-img img {
  max-width: 200px;
}

.payment-item {
  padding: 25px;
}

@media only screen and (min-width: 1024px) {
  .icon-container-dev-pm {
    display: unset;
  }

  .page-container-dev-pm {
    padding-left: 120px;
  }

  .text-wrapper-pm h1 {
    font-size: 56px;
  }
}