.motorhome-container h1 {
  color: var(--global--placeholder--Color);
  margin-bottom: 5px;
}

.motorhome-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  max-width: 300px;
}

.motorhome-personal-info-form,
.motorhome-address-info-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
}

.motorhome-personal-info,
.motorhome-address-info {
  width: 100%;
}

.motorhome-personal-info h1,
.motorhome-address-info h1 {
  font-size: 24px;
}

.column-motorhome-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
}

.motorhome-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.motorhome-input input,
.motorhome-input select {
  height: 30px;
  width: 220px;
  margin-top: 5px;
  margin-bottom: 15px;
  padding-left: 5px;
}

.motorhome-input select {
  height: 34px;
  width: 229px;
}

.accept-terms {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
}

.accept-terms input {
  margin-right: 10px;
}

.motorhome-submit-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;
}

.motorhome-submit {
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.motorhome-submit p {
  margin: 0;
}

.language-translation-motorhome {
  font-style: italic;
  color: #949393;
  margin: 0;
  font-size: 12px;
}

.language-translation-motorhome.main-title-translation,
.language-translation-motorhome.full-width-translation {
  width: 100%;
}

.accept-terms p {
  margin: 0;
}

.motorhome-submit .language-translation-motorhome {
  color: #dddddd;
  text-align: left;
}

.download-motorhome-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .motorhome-personal-info-form,
  .motorhome-address-info-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .motorhome-container {
    max-width: 500px;
  }
}