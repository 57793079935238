.icon-container-dev {
  height: 300px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
}
  
.icon-container-dev img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper-projects {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

@media screen and (min-width: 1300px) {
  .text-wrapper-projects {
    color: var(--global--placeholder--Color);
    display: flex;
    flex-direction: column;
    max-width: 800px;
  }
}

@media screen and (min-width: 1300px) {
  .text-wrapper-projects {
    color: var(--global--placeholder--Color);
    display: flex;
    flex-direction: column;
    max-width: 1000px;
  }
}

.text-wrapper-projects h1 {
  font-size: 28px;
}

.text-wrapper-projects h2 {
  font-size: 20px;
}

.page-container-projects {
  width: 80vw;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .page-container-projects {
    padding-left: 120px;
  }

  .text-wrapper-projects h1 {
    font-size: 56px;
  }

  .text-wrapper-projects h2 {
    font-size: 36px;
  }
}