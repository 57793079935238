.icon-container-dev {
  height: 300px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
  display: none;
}
  
.icon-container-dev img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper-subs {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

@media screen and (min-width: 1300px) {
  .text-wrapper-subs {
    color: var(--global--placeholder--Color);
    display: flex;
    flex-direction: column;
    max-width: 800px;
  }
}

@media screen and (min-width: 1300px) {
  .text-wrapper-subs {
    color: var(--global--placeholder--Color);
    display: flex;
    flex-direction: column;
    max-width: 1000px;
  }
}

.text-wrapper-subs h1 {
  font-size: 28px;
}

.page-container-dev-subs {
  padding-left: 10px;
}

@media only screen and (min-width: 1024px) {
  .icon-container-dev {
    display: unset;
  }

  .text-wrapper-subs {
    color: var(--global--placeholder--Color);
    display: flex;
    flex-direction: column;
    max-width: 600px;
  }

  .page-container-dev-subs {
    padding-left: 120px;
  }

  .text-wrapper-subs h1 {
    font-size: 56px;
  }
}