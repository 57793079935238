.icon-container-dev-faq {
  height: 300px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
  display: none;
}
  
.icon-container-dev-faq img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper-faq {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
}

.text-wrapper-faq h1 {
  font-size: 28px;
}

.page-container-dev {
  padding-left: 120px;
}

/* Embed */

.page-container-wrap {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}


.information-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 1024px) {
  .icon-container-dev-faq {
    display: unset;
  }

  .page-container-wrap {
    padding-left: 120px;
  }

  .text-wrapper-faq h1 {
    font-size: 56px;
  }
}