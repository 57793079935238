.icon-container-dev {
  height: 300px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
  display: none;
}
  
.icon-container-dev img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper-apr {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
}

.text-wrapper-apr h1 {
  font-size: 28px;
}

/* Embed */

.page-container-wrapper {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin: 0 auto;
}

.image-container {
  height: 700px;
  width: 80vw;
  margin: 0 auto;
  margin-bottom: 10px;
}

.image-container embed {
  height: 700px;
  width: 80vw;
}

.information-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 1024px) {
  .icon-container-dev {
    display: unset;
  }

  .text-wrapper-apr h1 {
    font-size: 56px;
  }

  .page-container-wrapper {
    width: 500px;
  }
  
  .image-container {
    width: 500px;
  }
  
  .image-container embed {
    width: 500px;
  }
}