:root {
  /* Background Colors */
  --global--primary--BackgroundColor: #FFFFFF;
  --global--secondary--BackgroundColor: #42518A;
  --global--ternary--BackgroundColor: #E9E9E9;
  --global--yellow-BackgroundColor: #F7F87B;
  --global--aqua-BackgroundColor: #00A49C;
  --global--notification--Color: #F55B5B;

  /* Font Colors */
  --global--primary--Color: #ffffff;
  --global--secondary--Color: #DDE5F1;
  --global--placeholder--Color: #222648;
  --global--error--Color: #F55B5B;
  --global--text--Color: #1E274A;

  --global--blue-box: rgb(63,83,135, 0.6);
  --global-dark-blue: #1D264C;
}

html {
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  background-color: var(--global--primary--BackgroundColor);
  height: 100%;
  width: 100%;
}

.page-sub-container {
  flex-grow: 1;
  margin-bottom: 100px;
  /* margin-top: 90px; */
}

@media only screen and (min-width: 1024px) {
  .page-sub-container {
    margin-top: 0px;
  }
}

.blue-button {
  background-color: var(--global--secondary--BackgroundColor);
  color: var(--global--primary--Color);
  border: none;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.transparent-button {
  background-color: transparent;
  color: var(--global--placeholder--Color);
  border: none;
  text-align: center;
  cursor: pointer;
  outline: none;
}
.transparent-button:hover {
  color: var(--global--secondary--BackgroundColor);
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-container-column {
  max-width: 1280px;
}