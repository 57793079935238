.icon-container-dev {
  display: none;
  height: 300px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
}

.icon-container-dev img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper-dev {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
}

.text-wrapper-dev h1 {
  font-size: 28px;
}

.page-container-news {
  max-width: 90vw;
  margin: 0 auto;
}

.comunicados {
  display: flex;
  flex-direction: column;
}

.com-sidebar {
  width: 85vw;
  min-width: 270px;
  margin: 0 auto;
}

.com-content {
  width: 85vw;
  min-width: 300px;
  margin: 0 auto;
}

.com-sidebar-item {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid var(--global--text--Color);
}

.com-sidebar-item:hover {
  cursor: pointer;
  background-color: #dedede;
}

.com-content img {
  max-width: 80vw;
}

.covid-table table {
  font-size: 12px;
}

@media screen and (min-width: 1024px) {
  .icon-container-dev {
    display: unset;
    height: 300px;
    width: 150px;
    position: absolute;
    right: 40px;
    top: 271px;
    object-fit: contain;
    overflow: hidden;
  }

  .icon-container-dev img {
    width: auto;
    height: 800px;
    position: absolute;
    left: -324px;
    top: -271px;
  }

  .text-wrapper-dev h1 {
    font-size: 56px;
  }

  .comunicados {
    display: flex;
    flex-direction: row;
  }

  .com-sidebar {
    width: 300px;
    min-width: 300px;
  }

  .com-content {
    padding-left: 50px;
  }

  .com-content img {
    max-width: 800px;
  }

  .page-container-news {
    padding-left: 120px;
  }

  .covid-table table {
    font-size: 18px;
  }

  .covid-table td,
  .covid-table th {
    padding: 10px;
  }
}

.alimentadora {
  max-width: 350px;
}

.alimentadora video {
  width: 300px;
  height: 180px;
}
.text-generic-news {
  width: 80%;
  text-align: justify;
}

@media screen and (min-width: 1024px) {
  .alimentadora video {
    width: 400px;
    height: 220px;
  }
}

@media screen and (min-width: 1196px) {
  .alimentadora {
    max-width: 500px;
  }

  .alimentadora video {
    width: 600px;
    height: 320px;
  }
}

@media screen and (min-width: 1324px) {
  .alimentadora {
    max-width: 700px;
  }

  .alimentadora video {
    width: 800px;
    height: 480px;
  }
}
