.icon-container-contact{
    height: 600px;
    width: 150px;
    position: absolute;
    right: 0px;
    top: 271px;
    object-fit: contain;
    /* border: 1px solid black; */
    overflow: hidden;
    display: none;
}
    
.icon-container-contact img {
    width: auto;
    height: 800px;
    position: absolute;
    left: -311px;
    top: -108px;
}

.contact-wrapper h1 {
    font-size: 28px;
    color: var(--global--placeholder--Color);
}

.contact-wrapper {
  margin: 0 auto;
  max-width: 300px;
}

@media only screen and (min-width: 1024px) {
  .icon-container-contact{
    display: unset;
  }

  .contact-wrapper h1 {
    font-size: 56px;
    color: var(--global--placeholder--Color);
  }

  .contact-wrapper {
    max-width: 700px;
  }
}