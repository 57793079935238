.footer-wrapper {
  width: 100%;
  height: 600px;
}

.footer-wrapper > hr {
  width: 100%;
}

.footer-container {
  width: 100%;
  height: 1600px;
  padding-bottom: 50px;
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: wrap;
}

.footer-container a:link,
.footer-container a:visited {
  text-decoration: none;
  color: var(--global--text--Color);
}

.footer-container a:hover {
  color: #454545;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: unset;
}

.footer-column > div {
  width: 250px;
}

.footer-container h1 {
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
}

.footer-container h2 {
  font-size: 12px;
  font-weight: 600;
}

.footer-container h3 {
  font-size: 12px;
  font-weight: 400;
}

.logo-container img {
  width: 100%;
  max-width: 200px;
  padding-bottom: 30px;
  height: auto;
}

.second-row-footer-column {
  padding-top: 20px;
}

.footer-social-media-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.footer-social-media-icons img {
  width: 35px;
  height: auto;
  margin-right: 10px;
}

.sub-division-footer-column {
  padding-bottom: 15px;
}

@media only screen and (min-width: 1024px) {
  .footer-container {
    height: 350px;
  }

  .footer-column {
    height: 100%;
    width: unset;
  }

  .footer-column > div {
    width: unset;
  }
}
