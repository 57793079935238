.icon-container-shortage {
  display: none;
}

.video-page-container {
  margin: 0 auto;
  max-width: 80vw;
}

.paragraph-wrapper iframe {
  width: 80vw;
  height: 40vw;
}

@media screen and (min-width: 1024px) {
  .paragraph-wrapper p {
    max-width: 800px;
  }

  .paragraph-wrapper iframe {
    width: 800px;
    height: 400px;
  }

  .video-page-container {
    padding-left: 120px;
  }
}

@media screen and (min-width: 1160px) {
  .icon-container-shortage {
    display: unset;
  }
}