.container {
  max-width: 400px;
  margin: 0 auto;
}

.info-title {
  text-align: center;
  padding-bottom: 40px;
  padding-top: 20px;
}
.info-title-img {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.info-check {
  text-align: center;
  margin-right: 10px;
}

.info {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.info-total {
  padding-top: 10px;
}

.info-check img {
  max-width: 40px;
  max-height: 40px;
  height: auto;
  width: auto;
}

.info-submit {
  text-align: center;
  width: 80px;
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.info-submit:hover {
  cursor: pointer;
}
