.dumpingPoints-map-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  height: auto;
  min-height: 600px;
  width: 100%;
}
.address-and-hours > div {
  padding: 15px 20px;
  border: 1px solid #000;
  border-radius: 3px;
  z-index: 10;
  margin: 0 0 15px 0;
  font-size: 13px;
  max-width: 700px;
  margin: 0px 20px 30px 0px;
}

.map-address-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-flow: wrap-reverse;
  max-width: 100%;
}

.dumpingPoints-list-container {
  margin: 20px 0px;
  /* padding-top: 400px; */
}
.dumpingPoints-list {
  padding-right: 0;
  display: flex;
  flex-direction: column;
}
.dumpingPoints-list-items:not(.open) {
  display: none;
  padding-top: 15px;
}
.dumpingPoints-list-items.open {
  display: block;
}
.dumpingPoints-list-items > button {
  padding-left: 25px;
  text-align: left;
  width: 270px;
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: -1px;
}

.dumpingPoints-list-title {
  font-weight: 600;
  border-radius: 3px;
  border: 1px solid black;
  background-image: url('/img/down-arrow.png');
  background-size: 15px 15px;
  background-position: 220px center;
  background-repeat: no-repeat;
  padding-left: 25px;
  text-align: left;
  width: 270px;
  height: 50px;
  display: flex;
  align-items: center;
}
.dumpingPoints-list-title.active {
  background-image: url('/img/up-arrow.png');
}
.dumpingPoints-list-title:hover {
  cursor: pointer;
}
.selected-office {
  background-color: rgb(235, 235, 235);
}

.office-info-title {
  width: 100%;
  font-size: 1.2rem;
}
.office-info-address {
  width: 100%;
}

.map-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 30px;
}

.map-display > div {
  max-width: 300px !important;
  max-height: 400px;
  margin-bottom: 30px;
}

.map-display > div > div {
  width: 300px !important;
  /* max-width: 90vw !important; */
}

/* @media only screen and (min-width: 768px) {
  .map-display > div {
    max-width: 500px !important;
  }

  .map-display > div > div {
    width: 500px !important;
  }
  .address-and-hours {
    width: 700px;
    display: flex;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 1024px) {
  .dumpingPoints-map-container {
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    max-width: 1200px;
  }

  .dumpingPoints-list-container {
    margin: 0 auto;
    padding-top: 0;
  }
  .dumpingPoints-list {
    padding-right: 50px;
  }
  .map-address-wrapper {
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-flow: wrap;
  }
  .dumpingPoints-list-items.open {
    display: block;
    padding-top: 15px;
  }
  .dumpingPoints-list-items:not(.open) {
    display: block;
    padding-top: 15px;
  }
  .dumpingPoints-list-items > button {
    width: 225px;
  }
  .dumpingPoints-list-title.active {
    background-image: url('/img/down-arrow.png');
  }
  .dumpingPoints-list-title {
    background-position: 190px center;
    width: 225px;
  }
  .dumpingPoints-list-title:hover {
    cursor: auto;
  }

  .map-display > div {
    max-width: 500px;
    width: 80vw !important;
    max-height: 400px !important;
  }
  .map-display > div > div {
    width: 500px !important;
  }
  .map-display {
    display: block;
    justify-content: start;
  }
} */
