.compliance-contact-form {
    height: auto;
    width: 250px;
    padding: 5px;
    list-style-type: none;
    color: var(--global--placeholder--Color);
}

.compliance-form-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.compliance-form-information {
    display: inline-block;
    padding-bottom: 10px;
}

.compliance-information-textarea {
    width: 250px;
    height: 125px;
    margin-top: 10px;
    resize: none;
}

.compliance-information-input {
    height: 30px;
    width: 250px;
    border: 1px solid var(--global--secondary--BackgroundColor);
    border-radius: 3px;
    margin-top: 10px;
}

.compliance-information-input-select {
  height: 30px;
  width: 600px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  border-radius: 3px;
  margin: 1px;
}
 

.compliance-information-submit {
    float: left;
    background-color: var(--global--placeholder--Color);
    border: none;
    border-radius: 5px;
    color: var(--global--primary--Color);
    width: 90px;
    height: 30px;
}

.compliance-success-message {
  color: #009900;
}

.compliance-error-message {
  color: red;
}

@media only screen and (min-width: 1024px) {
  .compliance-form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .compliance-information-textarea {
    width: 600px;
  }

  .compliance-contact-form {
    width: 600px;
  }
}
