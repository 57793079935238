.icon-container-dev {
  height: 300px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
}
  
.icon-container-dev img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper-forms {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}

.text-wrapper-forms h1 {
  font-size: 28px;
}

.text-wrapper-forms h2 {
  font-size: 20px;
}

.page-container-forms {
  width: 80vw;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .text-wrapper-forms h1 {
    font-size: 56px;
  }

  .text-wrapper-forms h2 {
    font-size: 36px;
  }

  .page-container-forms {
    padding-left: 120px;
  }
}

/* Embed */

.page-container-wrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0 auto;
}

.image-container {
  height: 700px;
  width: 500px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.image-container embed {
  height: 700px;
  width: 500px;
}

.information-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.file-gallery-wrapper {
  padding: 35px 0px;
}

.file-gallery {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 15px;
  padding-bottom: 20px;
}

.file-gallery-item {
  width: 100%;
  height: 100%;
  max-width: 380px;
  background-color: var(--global--primary--BackgroundColor);
  border-radius: 5px;
  box-shadow: 0px 5px 5px #eee;
}

.file-gallery-item h2 {
  font-size: 24px;
}

.file-gallery-item-text {
  padding: 20px 10px;
}

.file-gallery-item a:link,
.file-gallery-item a:visited {
  text-decoration: none;
  color: var(--global--text--Color);
}

.file-gallery-item-thumbnail {
  width: 380px;
  height: 180px;
  overflow: hidden;
}

.file-gallery-item-thumbnail img {
  max-width: 380px;
  max-height: 380px;
}

.load-more-link:hover {
  cursor: pointer;
  color: var(--global--aqua-BackgroundColor);
}

.load-more-link {
  background-color: var(--global--secondary--BackgroundColor);
  color: var(--global--primary--Color);
  border: none;
  border-radius: 5px;
  text-align: center;
  padding: 8px 12px;
  box-shadow: 0px 5px 5px #eee;
  max-width: 120px;
}