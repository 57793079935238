.winter-header {
  display: flex;
  align-items: stretch;
  margin-bottom: 50px;
  margin-top: 50px;
}

.winter-header p {
  line-height: 30px;
}
.winter-text-container {
  flex-grow: 1;
  width: 50%;
  padding: 50px;
  margin-right: 80px;
  border-radius: 0px 20px 20px 0px;
}

.winter-image-container {
  flex-basis: 40%;
  flex-grow: 0;
  max-height: 500px;
  overflow: hidden;
}

.winter-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 1024px) {

  .winter-header p {
    line-height: 30px;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1024px) {
  .winter-title {
    margin-left: 100px;
    margin-right: 150px;
    color: var(--global--text--Color);
  }
  
  .winter-title h1 {
    font-size: 48px;
  }
}

.winter-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
}

.winter-gallery img {
  max-width: 50%;
  height: auto;
}
.winter-carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.winter-carousel img {
  max-width: 50%;
  height: auto;
}

.winter-carousel-button {
  position: absolute;
  top: 50%;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2em;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin-top: -25px;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
}

.previous-button {
  left: -70px;
}

.next-button {
  right: -70px;
}