.sustainability-title {
  margin: 0 auto;
  width: 75vw;
  color: var(--global--text--Color);
}

.sustainability-title h1 {
  font-size: 28px;
}

.sustainability-title p {
  line-height: 30px;
  margin-top: 50px;
  margin-bottom: 100px;
}

@media screen and (min-width: 1024px) {
  .sustainability-title {
    margin-left: 100px;
    margin-right: 150px;
    color: var(--global--text--Color);
  }
  
  .sustainability-title h1 {
    font-size: 48px;
  }
  
  .sustainability-title p {
    line-height: 30px;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

/* Medioambiente */

.politica-medio-ambiental {
  position: relative;
}

.politica-medio-ambiental img {
  max-height: 700px;
  max-width: 75vw;
}

.politica-img {
  text-align: center;
}

.politica-img img {
  border-radius: 20px;
}

.politica-box {
  margin: 0 auto;
  margin-top: -35px;
  height: auto;
  width: 85vw;
  background-color: rgba(66, 81, 138, 0.7);
  border-radius: 25px;
}

.politica-box-content {
  padding: 50px;
}

.politica-box-content h2 {
  margin-top: 0px;
  font-size: 28px;
  color: #fff;
}

.politica-icon {
  display: none;
  position: absolute;
  right: 160px;
  top: 0px;
}

.politica-icon img {
  max-width: 100px;
  max-height: 100px;
}

@media screen and (min-width: 1024px) {
  .politica-medio-ambiental {
    position: relative;
    height: 700px;
  }
  
  .politica-medio-ambiental img {
    max-height: 700px;
    max-width: 80vw;
  }

  .politica-img {
    text-align: left;;
  }

  .politica-img img {
    border-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .politica-box {
    margin-top: 0px;
    position: absolute;
    top: 100px;
    right: 160px;
    height: auto;
    width: 700px;
    max-height: 464px;
    background-color: rgba(66, 81, 138, 0.7);
    border-radius: 25px;
  }
  
  .politica-box-content h2 {
    margin-top: 0px;
    font-size: 42px;
    color: #fff;
  }

  .politica-icon {
    display: unset;
    position: absolute;
    right: 160px;
    top: 0px;
  }
  
  .politica-icon img {
    max-width: 100px;
    max-height: 100px;
  }
}

.politica-box-content p {
  line-height: 1.7;
  color: #fff;
  font-size: 18px;
}

@media screen and (min-width: 1600px) {
  .politica-box {
    position: absolute;
    top: 100px;
    left: 750px;
  }

  .politica-icon {
    position: absolute;
    left: 1350px;
    top: 0px;
  }
}

/* Turismo */

.turismo {
  position: relative;
  height: auto;
  margin-top: 200px;
  margin-bottom: 200px;
}

.turismo img {
  max-height: 700px;
  max-width: 75vw;
}

.turismo-img {
  text-align: center;
}

.turismo-img img {
  border-radius: 20px;
}

.turismo-box {
  margin: 0 auto;
  height: auto;
  width: 85vw;
  background-color: rgba(66, 81, 138, 0.7);
  border-radius: 25px;
  margin-top: -35px;
}

.turismo-box-content h2 {
  margin-top: 0px;
  font-size: 28px;
  color: #fff;
}

.turismo-icon {
  display: none;
  position: absolute;
  left: 160px;
  top: 0px;
}

.turismo-icon img {
  max-width: 100px;
  max-height: 100px;
}

@media screen and (min-width: 1024px) {
  .turismo {
    position: relative;
    height: 700px;
    margin-top: 200px;
    margin-bottom: 200px;
  }
  
  .turismo img {
    max-height: 700px;
    max-width: 80vw;
  }

  .turismo-img {
    text-align: right;
  }

  .turismo-img img {
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .turismo-box {
    margin-top: 0px;
    position: absolute;
    top: 100px;
    left: 160px;
    height: auto;
    width: 700px;
    max-height: 464px;
    background-color: rgba(66, 81, 138, 0.7);
    border-radius: 25px;
  }

  .turismo-box-content h2 {
    margin-top: 0px;
    font-size: 42px;
    color: #fff;
  }

  .turismo-icon {
    display: unset;
    position: absolute;
    left: 160px;
    top: 0px;
  }
  
  .turismo-icon img {
    max-width: 100px;
    max-height: 100px;
  }
}

.turismo-box-content {
  padding: 50px;
}

.turismo-box-content p {
  line-height: 1.7;
  color: #fff;
  font-size: 18px;
}

@media screen and (min-width: 1600px) {
  .turismo-box {
    position: absolute;
    top: 100px;
    right: 750px;
  }

  .turismo-icon {
    position: absolute;
    right: 1350px;
    top: 0px;
  }
}

/* Emprendimiento */

.emprendimiento {
  position: relative;
}

.emprendimiento img {
  max-height: 700px;
  max-width: 75vw;
}

.emprendimiento-img {
  text-align: center;
}

.emprendimiento-img img {
  border-radius: 20px;
}

.emprendimiento-box {
  margin: 0 auto;
  margin-top: -35px;
  height: auto;
  width: 85vw;
  background-color: rgba(66, 81, 138, 0.7);
  border-radius: 25px;
}

.emprendimiento-box-content h2 {
  margin-top: 0px;
  font-size: 28px;
  color: #fff;
}

.emprendimiento-icon {
  display: none;
  position: absolute;
  right: 160px;
  top: 0px;
}

.emprendimiento-icon img {
  max-width: 100px;
  max-height: 100px;
}

@media screen and (min-width: 1024px) {
  .emprendimiento {
    position: relative;
    height: 700px;
  }
  
  .emprendimiento img {
    max-height: 700px;
    max-width: 80vw;
  }

  .emprendimiento-img {
    text-align: left;
  }

  .emprendimiento-img img {
    border-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .emprendimiento-box {
    margin-top: 0px;
    position: absolute;
    top: 100px;
    right: 160px;
    height: auto;
    width: 700px;
    max-height: 464px;
    background-color: rgba(66, 81, 138, 0.7);
    border-radius: 25px;
  }

  .emprendimiento-box-content h2 {
    margin-top: 0px;
    font-size: 42px;
    color: #fff;
  }

  .emprendimiento-icon {
    display: unset;
    position: absolute;
    right: 160px;
    top: 0px;
  }
  
  .emprendimiento-icon img {
    max-width: 100px;
    max-height: 100px;
  }
}

.emprendimiento-box-content {
  padding: 50px;
}

.emprendimiento-box-content p {
  line-height: 1.7;
  color: #fff;
  font-size: 18px;
}

@media screen and (min-width: 1600px) {
  .emprendimiento-box {
    position: absolute;
    top: 100px;
    left: 750px;
  }

  .emprendimiento-icon {
    position: absolute;
    left: 1350px;
    top: 0px;
  }
}

/* Educacion */

.educacion {
  position: relative;
  margin-top: 200px;
  margin-bottom: 200px;
}

.educacion-img {
  text-align: center;
}

.educacion-img img {
  border-radius: 20px;
}

.educacion img {
  max-height: 700px;
  max-width: 75vw;
}

.educacion-box {
  height: auto;
  width: 85vw;
  margin: 0 auto;
  margin-top: -35px;
  background-color: rgba(66, 81, 138, 0.7);
  border-radius: 25px;
}

.educacion-box-content h2 {
  margin-top: 0px;
  font-size: 28px;
  color: #fff;
}

.educacion-icon {
  display: none;
  position: absolute;
  left: 160px;
  top: 0px;
}

.educacion-icon img {
  max-width: 100px;
  max-height: 100px;
}

@media screen and (min-width: 1024px) {
  .educacion {
    position: relative;
    height: 700px;
    margin-top: 200px;
    margin-bottom: 200px;
  }
  
  .educacion-img {
    text-align: right;
    margin-right: 25px;
  }
  
  .educacion img {
    max-height: 700px;
    max-width: 80vw;
  }
  
  .educacion-box {
    margin-top: 0px;
    position: absolute;
    top: 100px;
    left: 160px;
    height: auto;
    width: 700px;
    max-height: 464px;
    background-color: rgba(66, 81, 138, 0.7);
    border-radius: 25px;
  }

  .educacion-box-content h2 {
    margin-top: 0px;
    font-size: 42px;
    color: #fff;
  }

  .educacion-icon {
    display: unset;
    position: absolute;
    left: 160px;
    top: 0px;
  }
  
  .educacion-icon img {
    max-width: 100px;
    max-height: 100px;
  }
}

.educacion-box-content {
  padding: 50px;
}

.educacion-box-content p {
  line-height: 1.7;
  color: #fff;
  font-size: 18px;
}

@media screen and (min-width: 1000px) {
  .educacion-img {
    margin-right: 100px;
  }
}

@media screen and (min-width: 1100px) {
  .educacion-img {
    margin-right: 150px;
  }
}

@media screen and (min-width: 1200px) {
  .educacion-img {
    margin-right: 200px;
  }
}

@media screen and (min-width: 1400px) {
  .educacion-img {
    margin-right: 250px;
  }
}

@media screen and (min-width: 1600px) {
  .educacion-box {
    position: absolute;
    top: 100px;
    right: 750px;
  }

  .educacion-img {
    margin-right: 300px;
  }

  .educacion-icon {
    position: absolute;
    right: 1350px;
    top: 0px;
  }
}

/* Inclusion */

.inclusion {
  position: relative;
}

.inclusion-img {
  text-align: center;
}

.inclusion img {
  max-height: 700px;
  max-width: 75vw;
}

.inclusion-box {
  margin: 0 auto;
  margin-top: -35px;
  height: auto;
  width: 85vw;
  background-color: rgba(66, 81, 138, 0.7);
  border-radius: 25px;
}

.inclusion-box-content h2 {
  margin-top: 0px;
  font-size: 28px;
  color: #fff;
}

.inclusion-icon {
  display: none;
  position: absolute;
  right: 160px;
  top: 0px;
}

.inclusion-icon img {
  max-width: 100px;
  max-height: 100px;
}

@media screen and (min-width: 1024px) {
  .inclusion {
    position: relative;
    height: 700px;
  }
  
  .inclusion-img {
    margin-left: 25px;
    text-align: left;
  }
  
  .inclusion img {
    max-height: 700px;
    max-width: 80vw;
  }
  
  .inclusion-box {
    margin-top: 0px;
    position: absolute;
    top: 100px;
    right: 160px;
    height: auto;
    width: 700px;
    max-height: 464px;
    background-color: rgba(66, 81, 138, 0.7);
    border-radius: 25px;
  }

  .inclusion-box-content h2 {
    margin-top: 0px;
    font-size: 42px;
    color: #fff;
  }

  .inclusion-icon {
    display: unset;
    position: absolute;
    right: 160px;
    top: 0px;
  }
  
  .inclusion-icon img {
    max-width: 100px;
    max-height: 100px;
  }
}

.inclusion-box-content {
  padding: 50px;
}

.inclusion-box-content p {
  line-height: 1.7;
  color: #fff;
  font-size: 18px;
}

@media screen and (min-width: 1000px) {
  .inclusion-img {
    margin-left: 100px;
  }
}

@media screen and (min-width: 1100px) {
  .inclusion-img {
    margin-left: 150px;
  }
}

@media screen and (min-width: 1200px) {
  .inclusion-img {
    margin-left: 200px;
  }
}

@media screen and (min-width: 1400px) {
  .inclusion-img {
    margin-left: 250px;
  }
}

@media screen and (min-width: 1600px) {
  .inclusion-box {
    position: absolute;
    top: 100px;
    left: 750px;
  }

  .inclusion-img {
    margin-left: 300px;
  }

  .inclusion-icon {
    position: absolute;
    left: 1350px;
    top: 0px;
  }
}