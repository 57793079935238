.navbar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navbar-top {
  display: none;
  height: 70px;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  background-color: var(--global--ternary--BackgroundColor);
  align-items: center;
}

.navbar-bottom {
  /* height: 200px; */
  height: 150px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-bottom-first {
  padding-left: 50px;
  padding-right: 80px;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #ccc; */
  box-shadow: 0px 5px 5px #eee;
}

.navbar-bottom-second {
  text-align: center;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--global--yellow-BackgroundColor);
}

.social-media-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.social-media-icons img {
  width: 35px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.phone-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.phone-wrapper img {
  width: 25px;
  height: auto;
  padding-right: 10px;
}

.phone-wrapper a:link,
.phone-wrapper a:visited {
  text-decoration: none;
  color: inherit;
}

.phone-wrapper p {
  font-weight: bold;
  color: var(--global--text--Color);
  font-size: 16px;
}

.navbar-top-tab {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-top-tab .transparent-button {
  height: 70px;
}

.navbar-top-tab .blue-button {
  height: 35px;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  align-items: center;
}

.main-button > .blue-button {
  height: 70px;
  width: 175px;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 5px 5px 5px #ababab;
}

.main-button > .blue-button:hover {
  opacity: 0.7;
}

.payment-nav-button > .blue-button {
  background-color: var(--global--aqua-BackgroundColor);
}

.nav-hr {
  height: 15px;
  /* background-color: transparent; */
  background-color: var(--global--ternary--BackgroundColor);
  /* padding-top: 2px; */
}

.nav-hr hr {
  z-index: 200;
}

/* dropdown-content */

.nav-drop-container {
  float: left;
  overflow: hidden;
}

.nav-drop-content {
  display: none;
  position: absolute;
  top: 70px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  background-color: var(--global--primary--BackgroundColor);
  border-top: 2px solid var(--global--text--Color);
}

.nav-drop-content a {
  float: none;
  color: var(--global--text--Color);
  /* background-color: var(--global--primary--BackgroundColor); */
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  text-align: left;
  text-transform: none;
  font-size: 14px;
}

.nav-drop-container hr {
  border-color: var(--global--secondary--BackgroundColor);
  width: 100%;
  display: none;
  position: absolute;
  /* margin: 15px 0px 10px 0px; */
}

.nav-drop-container:hover .nav-drop-content,
.nav-drop-container:hover hr {
  display: block;
}

/* second level dropdown content */
.nav-second-drop-container {
  position: relative;
  /* float: left; */
}

.nav-second-drop-content {
  display: none;
  position: absolute;
  left: 100%;
  top: 0px;
  min-width: 100%;
  width: 200px;
  z-index: 1;
  background-color: var(--global--primary--BackgroundColor);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.nav-second-drop-content a:hover {
  background-color: var(--global--ternary--BackgroundColor);
}

.nav-second-drop-container:hover .nav-second-drop-content {
  display: block;
}

.nav-drop-wrapper > a:hover {
  background-color: var(--global--ternary--BackgroundColor);
}

.nav-second-drop-container:hover {
  background-color: var(--global--ternary--BackgroundColor);
}

.arrow {
  background-image: url('/img/right-arrow.png');
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 10px center;
}

/* mobile navbar */
.navbar-top-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.navbar-bottom-mobile-logo img {
  width: 50px;
  height: auto;
}

.burger-button {
  width: 50px;
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.burger-button > div {
  height: 3px;
  width: 25px;
  background-color: var(--global--secondary--BackgroundColor);
}

.burger-menu {
  height: 100vh;
  width: 200px;
  z-index: 100;
  position: absolute;
  transition-duration: 300ms;
  top: 0px;
  left: 0px;
  background-color: var(--global--secondary--BackgroundColor);
  color: var(--global--primary--Color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.burger-menu-tabs {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 400px;
  justify-content: space-evenly;
}

.burger-menu-tabs a,
.burger-menu-tabs a:link,
.burger-menu-tabs a:visited {
  text-decoration: none;
  color: var(--global--primary--Color);
  font-size: 20px;
}

.hidden-burger {
  left: -200px;
}

.main-mobile-button > .blue-button {
  height: 30px;
  width: 100px;
}

.menu-main-mobile-button > .blue-button {
  height: 50px;
  width: 130px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

@media screen and (min-width: 1024px) {
  .menu-main-mobile-button > .blue-button {
    height: 50px;
    width: 130px;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
  }
}

.backdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  opacity: 0.2;
  z-index: 50;
  background-color: var(--global--ternary--BackgroundColor);
}

.hidden-backdrop {
  display: none;
}

@media only screen and (min-width: 450px) {
  .navbar-bottom-second {
    font-size: unset;
  }

  /* .hidden-burger {
    left: -300px;
  }

  .burger-menu {
    width: 300px;
  }
  .burger-menu-tabs a,
  .burger-menu-tabs a:link,
  .burger-menu-tabs a:visited {
    font-size: 28px;
  } */
}

@media only screen and (min-width: 1024px) {
  .navbar-top {
    display: flex;
  }

  .navbar-top-mobile {
    display: none;
  }

  .navbar-bottom {
    display: flex;
  }

  .navbar-bottom-mobile {
    display: none;
  }
}

@media only screen and (min-width: 1250px) {
  .main-button > .blue-button {
    height: 50px;
    width: 250px;
    font-size: 18px;
  }
}