.icon-container-dev {
  height: 300px;
  width: 150px;
  position: absolute;
  right: 40px;
  top: 271px;
  object-fit: contain;
  overflow: hidden;
}
  
.icon-container-dev img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -324px;
  top: -271px;
}

.text-wrapper-covid {
  color: var(--global--placeholder--Color);
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}

.text-wrapper-covid h1 {
  font-size: 28px;
}

.page-container-covid {
  width: 80vw;
  margin: 0 auto;
}

.covid-img img {
  max-width: 80vw;
}

.covid-table table {
  font-size: 12px;
}

.covid-table td,
.covid-table th {
  padding: 0px;
}

.covid-collapse {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1024px) {
  
  .text-wrapper-covid h1 {
    font-size: 56px;
  }
  
  .page-container-covid {
    padding-left: 120px;
  }

  .covid-table table {
    font-size: 18px;
  }

  .covid-table td,
  .covid-table th {
    padding: 10px;
  }
}