.icon-container-shortage {
height: 300px;
width: 150px;
position: absolute;
right: 40px;
top: 271px;
object-fit: contain;
overflow: hidden;
}

.icon-container-shortage img {
    width: auto;
    height: 800px;
    position: absolute;
    left: -324px;
    top: -271px;
}

.text-wrapper-shortage {
    color: var(--global--placeholder--Color);
    display: flex;
    flex-direction: column;
    /* text-align: justify; */
}

.text-wrapper-shortage iframe {
  width: 100%;
  max-width: 1300px;
  height: 560px;
}

.text-wrapper-shortage h1 {
    font-size: 28px;
}

.more-information {
    border: 1px solid var(--global--placeholder--Color);
    background-color: var(--global--primary--BackgroundColor);
    border-radius: 3px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    padding: 2px 10px;
}

.more-information img {
    height: 20px;
    width: auto;
    margin-right: 12px;
}

.shortage-page-container {
  width: 80vw;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
    .shortage-page-container {
        padding-left: 120px;
    }

    .text-wrapper-shortage h1 {
        font-size: 56px;
    }
}