.icon-container-work-with-us {
  display: none;
  height: 600px;
  width: 150px;
  position: absolute;
  right: 0px;
  top: 271px;
  object-fit: contain;
  /* border: 1px solid black; */
  overflow: hidden;
}
  
.icon-container-work-with-us img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -311px;
  top: -108px;
}

.work-with-us-wrapper {
  max-width: 80vw;
  margin: 0 auto;
}

.work-with-us-wrapper h1 {
  font-size: 28px;
  color: var(--global--placeholder--Color);
}

.work-with-us-wrapper p {
  line-height: 25px;
  color: var(--global--placeholder--Color);
  margin-bottom: 50px;
}

@media screen and (min-width: 1024px) {
  .work-with-us-wrapper {
    max-width: 600px;
    margin: 0 auto;
  }

  .work-with-us-wrapper h1 {
    font-size: 56px;
    color: var(--global--placeholder--Color);
  }

  .icon-container-work-with-us {
    display: unset;
    height: 600px;
    width: 150px;
    position: absolute;
    right: 0px;
    top: 271px;
    object-fit: contain;
    /* border: 1px solid black; */
    overflow: hidden;
  }
    
  .icon-container-work-with-us img {
    width: auto;
    height: 800px;
    position: absolute;
    left: -311px;
    top: -108px;
  }
}