.icon-container-denuncias {
  height: 600px;
  width: 150px;
  position: absolute;
  right: 0px;
  top: 271px;
  object-fit: contain;
  /* border: 1px solid black; */
  overflow: hidden;
  display: none;
}
  
.icon-container-denuncias img {
  width: auto;
  height: 800px;
  position: absolute;
  left: -311px;
  top: -108px;
}

.denuncias-wrapper h1 {
  font-size: 28px;
  color: var(--global--placeholder--Color);
}

.denuncias-wrapper {
  margin: 0 auto;
  max-width: 300px;
}

.denuncias-information-input {
  height: 30px;
  width: 250px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  border-radius: 3px;
  margin-top: 10px;
  margin-left: 15px;
  padding-left: 5px;
}

.denuncias-information-input-select {
  height: 30px;
  width: 600px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  border-radius: 3px;
  margin: 1px;
}

.denuncias-information-submit-btn {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'flex-start';
}

.denuncias-information-submit {
  float: left;
  background-color: var(--global--placeholder--Color);
  border: none;
  border-radius: 5px;
  color: var(--global--primary--Color);
  width: 90px;
  height: 30px;
  margin: 0px 15px;
}

.denuncias-information-submit:hover {
  cursor: pointer;
}

.denuncias-information-submit-file {
  float: left;
  background-color: var(--global--placeholder--Color);
  border: none;
  border-radius: 5px;
  color: var(--global--primary--Color);
  width: 180px;
  height: 30px;
  margin: 0px 15px;
}

.denuncias-information-submit-file:hover {
  cursor: pointer;
}

.denuncias-information-submit:disabled {
  cursor: not-allowed;
  color: #000;
  background-color: #ccc;
}

.p-small {
  font-size: 12px;
}

.p-error {
  color: red;
  font-size: 16px;
}

.denuncias-item-row {

}

.denuncias-item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  padding-bottom: 5px;
}

.denuncias-item-radio {
  padding: 4px 0px;
}

.denuncias-item-info {
  text-decoration: underline;
}

.denuncias-item-info:hover {
  cursor: pointer;
}

.denuncias-content {
  margin-left: 20px;
  background-color: #efefef;
  padding: 5px;
}

.denuncias-content span {
  margin-right: 3px;
  font-weight: 600;
}

.denuncias-content ul {
  margin-left: 20px;
}

.denuncias-item-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.denuncias-item-text label {
  font-weight: 600;
}

.denuncia-files {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.denuncia-files a {
  margin: 15px 0px;
}

.denuncia-files a:hover {
  cursor: pointer;
}

.denuncia-files a:link {
  color: var(--global--text--Color);
  text-decoration: underline;
}

.denuncia-files a:visited {
  color: var(--global--text--Color);
  text-decoration: underline;
}

@media only screen and (min-width: 1024px) {
  .icon-container-denuncias {
    display: unset;
  }

  .denuncias-wrapper h1 {
    font-size: 56px;
    color: var(--global--placeholder--Color);
  }

  .denuncias-wrapper {
    max-width: 700px;
  }
}