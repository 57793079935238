.customers-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.customers-title-container h1 {
  font-size: 28px;
  color: var(--global--placeholder--Color);
}

.customers-title-container {
  padding-bottom: 0px;
}

.customer-boxes-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1400px;
  width: 100%;
  /* align-items: space-evenly; */
}

.customer-boxes-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  height: 50%;
}

.customer-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  width: 230px;
  height: 240px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgb(153, 152, 152);
  overflow: hidden;
}

.customer-box h2 {
  margin: 0;
  font-size: 19px;
  font-weight: 600;
  z-index: 10;
}

.customer-box p {
  width: 250px;
  z-index: 10;
  line-height: 25px;
}

.places-payments {
  width: 150px;
  padding-left: 75px;
  margin: 0 auto;
  z-index: 10;
}

.links-customer-box {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.links-customer-box a {
  margin: 5px 0px;
  text-decoration: underline;
  font-size: 16px;
}

.communications-customer-box .blue-button {
  background-color: #2F51CF;
  width: 100px;
  height: 30px;
  z-index: 10;
}

.communications-customer-box img {
  position: absolute;
  bottom: 20px;
  left: 200px;
  width: 100px;
  height: auto;
}

.shortage-customer-box img,
.payments-customer-box img {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: auto;
}

.shortage-customer-box .blue-button {
  background-color: var(--global--primary--BackgroundColor);
  color: #2F51CF;
  width: 150px;
  height: 30px;
  z-index: 10;
}

.sales-customer-box img {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 100px;
  height: auto;
}

img.light-blue-circle-communications {
  position: absolute;
  left: unset;
  right: -320px;
  top: -20%;
  height: 140%;
  width: auto;
}

img.blue-circle-communications {
  position: absolute;
  left: -320px;
  top: unset;
  bottom: -110%;
  height: 140%;
  width: auto;
}

img.light-blue-circle-shortage {
  position: absolute;
  left: -100px;
  top: -20%;
  height: 140%;
  width: auto;
}

img.blue-circle-payments {
  position: absolute;
  left: -330px;
  top: unset;
  bottom: -20%;
  height: 140%;
  width: auto;
}

img.calypso-circle-sales {
  position: absolute;
  left: unset;
  right: -270px;
  top: unset;
  bottom: -90%;
  height: 140%;
  width: auto;
}

.shortage-customer-box h2,
.shortage-customer-box p {
  color: var(--global--primary--Color);
}

@media only screen and (min-width: 1024px) {
  .customer-boxes-container {
    height: 700px;
    width: 1000px;
    align-items: unset;
    /* align-items: space-evenly; */
  }

  .customer-boxes-row {
    height: unset;
    align-items: unset;
    justify-content: space-between;
  }

  .customer-box {
    padding: 30px;
    width: 390px;
    height: 240px;
  }

  .customers-container {
    max-width: 1000px;
  }

  .customers-title-container h1 {
    font-size: 56px;
  }

  .customers-title-container {
    padding-bottom: 50px;
  }

  img.blue-circle-payments {
    left: -270px;
  }
}